.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 300px;
    box-sizing: border-box;
    background: #b8edff;
    padding: 20px;
    align-items: flex-start;
}

.body {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    margin-bottom: 20px;
    padding: 3px;
    font-size: 22px;
}

.text {
    overflow-wrap: break-word;
    resize: none;
    font-size: 16px;
    padding: 6px;
}

.buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
