.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  padding: 6px 4px;
  border: 1px solid green;
  background: white;
  font-size: 16px;
}

.content {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row {
  width: 100%;
  display: flex;
  gap: 8px;
}

.key {
  width: 150px;
}

.value {
  width: 150px;
}


