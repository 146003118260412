.root {
  width: 100%;
  height: 60px;
  position: sticky;
  gap: 6px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid teal;
  padding: 0 10px;
}

.item {
  padding: 8px 16px;
  border: 1px solid black;
}
